// terceros
export const CHART_GOOGLE_PATH = 'https://chart.googleapis.com';
export const MY_TALISIS = 'https://my-talisis.inteam.app/';

// prefijos principales, ordernar alfabeticamente
export const ROOT_HOME = '/'
export const ACCESO = '/access';
// export const ASESORIAS = '/asescorias';
export const BILLING = '/billing';
export const BLOG = 'https://blog.talisis.com/all';
export const COMUNIDAD = "/comunidad";
export const CURSOS = '/cursos';
export const COURSES = '/courses';
export const COMERCIAL = '/comercial';
export const CURSO = '/course';
export const EVENTOS = '/eventos';
export const FEED = "/feed";
export const GESTION_SERVICIOS = '/gestion-de-servicios';
export const HOME = "/home";
export const INSTRUCTOR = '/instructor';
export const KARDEX = '/kardex';
export const MIS_CURSOS = '/my-courses';
export const NOTIFICACIONES = '/notifications'
export const PERFIL = '/profile';
export const SOLICITUDES = '/solicitudes';
export const SIMULACION = '/simulation';
export const USUARIOS = '/usuarios';
export const GESTION_DE_SERVICIOS = '/gestion-de-ordenes';
export const ROLES = '/roles';
export const PERMISOS = '/permisos';
export const SYSTEM = '/system';
export const SIMPLICITY = '/conecta';
export const RECORD = '/expedientes';
export const SPIN_BY_OXXO = '/spinbyoxxo';
export const EDUCACION_CONTINUA = '/educacion-continua';
export const HELPGUIDE = '/help-guide/:name/:tag';

// market
export const CURSO_DETALLE = `/course/:id`
export const CONFIRMACION_PAGO = `/payment-confirmation`
export const CONFIRMACION_PAGO_TRANSFERENCIA = `/payment-confirmation-transfer`
export const CONFIRMACION_INSCRIPCION = `/confirmacion-inscripcion`
export const PROGRAMA = `/programa/:id`
export const ALIADOS= `/aliados/:name`
export const ESCUELAS_OUS = `/escuelas/:brand`
export const MEMBRESIAS = `/membresias`
export const MEMBRESIAS_OLD = `/membresias/old`
export const MEMBRESIAS_PLAN_MENSUAL = `${MEMBRESIAS}/plan-mensual`
export const CHECKOUT_LEGACY = `/checkout/legacy`
export const CHECKOUT = `/checkout`
export const CHECKOUT_PAYMENT_LINK = `${CHECKOUT}/payment-link/:token`
export const PAYMENT_CONFIRMATION = `${CHECKOUT}/confirmacion-pago/:order_id`;
export const CART = `/carrito`

// general
export const SOBRE_NOSOTROS = `/about-us`
export const TERMINOS_CONDICIONES = `/terminos_condiciones`
export const AVISO_PRIVACIDAD = `/aviso_privacidad`
export const BUSQUEDA = `/busqueda`
export const PROMO = `/promo/:propagandaId`;
export const CO_COUPONS_MANAGEMENT = `${COMERCIAL}/promociones/descuentos`;
export const CO_COUPONS_CREATE = `${COMERCIAL}/promociones/descuentos/crear`;
export const CO_COUPONS_EDIT = `${COMERCIAL}/promociones/descuentos/editar`;

// session
export const LOGIN = `/login`
export const SIGNUP = `/signup`
export const SIGNUP_REFERER_ID = `/signup/:referer_id`
export const PASSWORD_TOKEN = `/password/:token`
export const PASSWORD_SSO_TOKEN = `/password-sso/:token`
export const AUTH = `/auth`
export const RECOVER_PASSWORD = `/recover-password`
// eventos
export const EVENTOS_DETALLE = `${EVENTOS}/:id`;

// feed
export const FEED_NUEVO = `${FEED}/new`;
export const FEED_EDITAR = `${FEED}/:id/edit`;

// test vocacional
export const TEST_VOCACIONAL = '/test-vocacional';
export const TEST_VOC_ONLINE_UNID = `${TEST_VOCACIONAL}/UNID`;
export const TEST_VOC_ONLINE_UERRE = `${TEST_VOCACIONAL}/UERRE`;
export const TEST_VOC_CAMPUS_UNID = `${TEST_VOCACIONAL}/campus/UNID`;
export const TEST_VOC_CAMPUS_UERRE = `${TEST_VOCACIONAL}/campus/UERRE`;

export const CONTACTANOS = '/contactanos';
export const CONTACTANOS_UNID = `${CONTACTANOS}/:type(maestria|licenciatura|bachillerato)/UNID`;
export const CONTACTANOS_UERRE = `${CONTACTANOS}/:type(maestria|licenciatura|bachillerato)/UERRE`;

// Cursos
export const MIS_CURSOS_COMPLETOS = `${MIS_CURSOS}/completed`;

export const CATALOGO_CURSOS_DETALLE = `${COURSES}/catalog/:id`;
export const COURSES_MANAGEMENT = `${COURSES}/management`;

export const CURSO_EDITAR = `${CURSO}/:id/edit`;
export const CURSO_NUEVO = `${CURSO}/new`;
export const CURSOS_ACTUALES_DETALLE = `${CURSOS}/actules/:id`;
export const CURSOS_DETALLE = `${CURSOS}/:id`;
export const CURSOS_TALISIS = `${CURSOS}/cursar/:id`

export const INSTRUCTOR_NUEVO = `${INSTRUCTOR}/new`;
export const INSTRUCTOR_EDITAR = `${INSTRUCTOR}/:id/edit`;

// Acceso
export const ACCESO_REGRESO_PRESENCIAL = `${ACCESO}/regreso-presencial`

// solicitudes
export const SOLICITUDES_DETALLE = `${SOLICITUDES}/:id`;
export const SOLICITUDES_CARGA_ARCHIVOS = `${SOLICITUDES_DETALLE}/envio-de-documentos`;
export const ESTATUS_ORDENES = '/gestion-de-ordenes';
export const GESTION_SERVICIOS_CREAR = `${GESTION_SERVICIOS}/crear`;
export const GESTION_SERVICIOS_EDITAR = `${GESTION_SERVICIOS}/:id/editar`;

export const GESTION_SERVICIO_CONFIGURACION_PASO = `${GESTION_SERVICIOS_EDITAR}/configuracion-paso`
export const GESTION_SERVICIO_EDITAR_PASO = `${GESTION_SERVICIOS_EDITAR}/configuracion-paso/:stepId`
export const GESTION_SERVICIO_NEW_STEP = `${GESTION_SERVICIOS}/configuracion-paso/:stepId?`

export const GESTION_SERVICIO_SHOW = `${GESTION_SERVICIOS}/:id`
export const GESTION_SERVICIO_SHOW_STEP = `${GESTION_SERVICIOS}/:id/configuracion-paso/:stepId?`

// Notificaciones
export const NOTIFICACIONES_CREAR = `${NOTIFICACIONES}/new`;
export const NOTIFICACIONES_EDITAR = `${NOTIFICACIONES}/:id/edit`;

// usuarios
export const USUARIOS_GRUPOS = `${USUARIOS}/grupos`;
export const USUARIOS_GRUPOS_CREAR = `${USUARIOS_GRUPOS}/crear`;
export const USUARIOS_GRUPOS_EDITAR = `${USUARIOS_GRUPOS}/:id/editar`;
export const USUARIOS_HEALTH_CHECK = `${USUARIOS}/health-check`;
export const USUARIOS_MANAGMENT = `${USUARIOS}/management`;
export const USUARIOS_EDITAR = `${USUARIOS}/:id/editar`;
export const USUARIOS_CREAR = `${USUARIOS}/crear`;
export const USUARIOS_ROLES = `${USUARIOS}/:id${ROLES}`;

//degree-certificate
export const TITLU_CERT = '/titulacion-certificacion';
// export const TITLU_CERT_STUDENTS_DETAILS= `${TITLU_CERT}/students/details`;
export const TITLU_CERT_STUDENT_EDIT = `${TITLU_CERT}/students/:matricula/editar/:program/:ou`;

// roles
export const ROLES_MANAGMENT = `${ROLES}/management`;
export const ROLES_CREAR = `${ROLES}/crear`;
export const ROLES_EDITAR = `${ROLES}/:id/editar`;
export const ROLES_PERMISOS = `${ROLES}/:id${PERMISOS}`;

// permisos
export const PERMISOS_ADMIN = `${PERMISOS}/management`
export const PERMISOS_CREAR = `${PERMISOS}/crear`
export const PERMISOS_EDITAR = `${PERMISOS}/:id/editar`

// DS
export const SYSTEM_TYPOGRAPHY = `${SYSTEM}/typography`;
export const SYSTEM_PALETTE = `${SYSTEM}/palette`;

// Mis clases
export const PROGRAMAS_ID_CURSOS = `/programas/:id/cursos/:student`;
export const ALIADOS_SOURCE_ID_COURSOS = `/aliados/:source_id/cursos`;
export const EDUCON_COMPANY_ID_COURSOS = `/educon/:company_id/cursos`;

// //Asesorias 
// export const PERFIL_ASESORIAS = `${ASESORIAS}/asesor/:id`;
// export const CALIFICAR_ASESORIA = `${ASESORIAS}/calificar`;

// HTTP Errors
export const HTTP_ERROR_404 = "/recurso-no-encontrado";

// Refiere y gana
export const REFIERE_GANA_PATH = "/refiere-y-gana";
export const REFIERE_UN_AMIGO_PATH = "/refiere-un-amigo/:referer_id";
export const CHART_GOOGLE_QR_PATH = `${CHART_GOOGLE_PATH}/chart?cht=qr`;

export const RECEPCION_DOCUMENTOS_PATH = '/recepcion-documentos';
export const RECEPCION_DOCUMENTOS_DETALLE_PATH = `${RECEPCION_DOCUMENTOS_PATH}/:id`;


