import React, { lazy, Suspense } from 'react';

// @redux
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import {userIsLogged } from '../../shared/utils/Sessions' ;

//material
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

//components
import Onboarding from '../Onboarding/Onboarding';
import SecctionAcademias from './Academias/Academias';
import UpcomingEvents from '../Events/UpcomingEvents/UpcomingEvents'

//SDK
import { TourService } from '@sdk-point/talisis';
import SectionWhatsapp from './Sections/Whatsapp';
import SectionBanner from './Sections/BannerTesting';
import BannerBySeason from './Sections/BannerBySeasonVideo';

// import LearningAccorddingSection from './Sections/LearningAccordding';

// @dependencies
import OurPrograms from './Sections/OurPrograms';
import OnlineBachProgram from './Sections/OnlineBachProgram';
import BannerHH from './Sections/BannerHH';

// Shared - constants
import {COMPANIES, EDU_OFFER} from '../../shared/constants/filters-search'
import Allys from './Allys/Allys';
import { eventData, eventTracker } from '../../shared/components/EventTracker/EventTracker';
import { type } from 'os';


//Lazy components
const CourseCarousel = lazy(() => import('../../shared/components/CourseCarousel/CourseCarousel'));
const ProgramCarousel = lazy(() => import('../../shared/components/ProgramCarousel/ProgramCarousel'));

//SDK Launch
const TourServiceSDK = new TourService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const APPBAR_HEIGHT = 64;


const useStyles = makeStyles((theme) => ({
    programCarouselContainer: {
        justifyContent: "center",
        maxWidth: theme.breakpoints.maxWidth.desktop,
        margin: 'auto',
        padding: '64px 0',
        [theme.breakpoints.down('xs')]:{
            minHeight: '0px !important',
            padding: '40px 15px 38px !important',
        }
    },
    programCarousel:{
        '& .title-link':{
            marginLeft: 0,
            marginBottom: 8
        }
    },
    allysCarousel:{
        paddingTop: 56,
        paddingBottom: 56
    }
}));

const ExploreHome = (props) => {
    const classes = useStyles();
    const isLogged = userIsLogged();
    const history = useHistory();
    const [chargeGeneric, setChargeGeneric] = React.useState(false);
    const [chargeGenericSecond, setChargeGenericSecond] = React.useState(false);
    const [onboarding, setOnboarding] = React.useState(false);
    const [typeBanner, setTypeBanner] = React.useState(0);
    const browseScrollRef = React.useRef(null);
    const { user } = props;

    const handleAutoScroll = () => {
        const position = window.pageYOffset;
      
        if (position >= 2300 && !chargeGeneric) {
          setChargeGenericSecond(true);
        } else if (position >= 2750 && !chargeGenericSecond) {
          setChargeGenericSecond(true);
        }
      };

      const infoBanners = [
        {   
            title: 'prueba A/B test banner',
            banner: 'Banner_pareja',
            link: ''
        },
        {
            title: 'prueba A/B test banner',
            banner: 'Banner_estudiante',
            link: ''
        },
        {
            title: 'prueba A/B test banner',
            banner: 'Banner_estudiateComputadora',
            link: 'https://b2b.talisis.com'
        },
        {
            title: 'prueba A/B test banner',
            banner: 'BannerTradicional',
            link: ''
        },
    ]

    function getRandomInt(max) {
        const bannerPositions = [1,3];
        const index = Math.floor(Math.random() * bannerPositions.length);
        return bannerPositions[index];
        // return Math.floor(Math.random() * max);
    }

    React.useEffect(() => {
        let BannerRandomTestAb = getRandomInt(4);
        setTypeBanner(BannerRandomTestAb);
        
    },[]);
      
    React.useEffect(() => {
        const getFirstTime = async (personId) => {
            try {
                const response = await TourServiceSDK.getifItsFirstTime(personId);
                setOnboarding(response.tour_initial);
            } catch (error) {
                setOnboarding(false);
                return false;
            }
        };
      
        const cleanup = () => {
            window.removeEventListener('scroll', handleAutoScroll);
            window.onbeforeunload = null;
        };
      
        getFirstTime(user.person_id);
        window.addEventListener('scroll', handleAutoScroll, { passive: true });
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        
        eventTracker('s_landing',eventData, ['mixpanel', 'hubspot','google']);
        return cleanup;
    }, [chargeGeneric, chargeGenericSecond, user.person_id]);

    const events_academias = () => {
        eventTracker('s_academias', eventData, ['mixpanel', 'hubspot', 'google']);
    }
      

    const handleScroll = () => {
        if (browseScrollRef.current) {
            const scrollTop = browseScrollRef.current.offsetTop - APPBAR_HEIGHT;

            window.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    };

    const handleToLogin = () => {
        eventData['source'] = 'Landing_hero';
        eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);

        eventData['source'] = 'ExploreHome';
        eventData['NameBanner'] = infoBanners[typeBanner].banner;
        eventData['LinkBanner'] = infoBanners[typeBanner].LinkBanner;
        eventTracker('Banner', eventData, ['mixpanel']);
        history.push('/signup')
    };

    return (
        <Box >
            {/* onboarding ? <Onboarding user={user}/> : <Box style={{ zIndex: "1", position: "relative" }} /> */}
            { 
                !isLogged 
                // ? <BannerBySeason />
                ? <SectionBanner onClickLogin={handleToLogin} type={typeBanner}/>
                : isLogged && <SecctionAcademias eventClick={events_academias}/>
            }
            <OurPrograms />
            <BannerHH />
            <Box className='bg-dark-black-500'>
                <Box className={classes.programCarouselContainer}>
                    <Suspense fallback={<div></div>}>
                        <ProgramCarousel
                            autoResponsive={false}
                            titleVariant="h3"
                            className={classes.programCarousel}
                            isDetail={true} 
                            // type="licenciatura" 
                            filterParams={{                        
                                educativeOffer:[EDU_OFFER.LICENCIATURA, EDU_OFFER.MAESTRIA],
                                companies:[COMPANIES.UERRE, COMPANIES.UNID]
                            }}
                            title='Conoce las Licenciaturas y Maestrías más destacadas' 
                            ou='UNID'
                            showAllPath='/busqueda?programas=true'
                        />
                    </Suspense>
                </Box>
            </Box>

            { !isLogged && 
                <React.Fragment>
                    <SectionWhatsapp onClickLogin={handleToLogin}/>
                </React.Fragment>
            }
            <OnlineBachProgram />
            <Allys />

            {
                !isLogged && 
                <>
                    {/* <LearningAccorddingSection /> */}
                    <SecctionAcademias eventClick={events_academias}/>
                </>
                
            }

            <Suspense fallback={<div></div>}>
                <CourseCarousel
                    lazyLoading
                    id='catalog::discovery_courses'
                    landing={true}
                    title='Explora nuestra variedad de cursos'
                    urlMore='/busqueda?explorar=true'
                    viewCourseDetails
                    clickFrom={'landing'}
                    hideShowMoreResponsive={true}
                />
            </Suspense>                   

            <UpcomingEvents isLogged={isLogged}/>
        </Box>
    );
};

const mapStateToProps = ({tourInitialReducer, userReducer}) => ({
    ...tourInitialReducer,
    ...userReducer
});

export default connect(mapStateToProps, {})(ExploreHome);
