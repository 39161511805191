import { 
    Home as MarketHomeLayout,
    ExternalVocationalTest as ExternalVocationalTestLayout,
    OnlineVocationalTest as OnlineVocationalTestLayout,
    UnidLayout, UerreLayout,
    RedirectPage
} from '../layout';

// Market
import MarketPaymentConfirmation from '../pages/Market/PaymentConfirmation/PaymentConfirmation';
import MarketEnrollmentConfirmation from '../pages/Market/EnrollmentConfirmation/EnrollmentConfirmation';
import AlliesLandingPage from '../pages/Market/AlliesLandingPage/AlliesLandingPage';
import MarketSearch from '../pages/Market/Public/pages/Search/Search';
import GenericBrandLandingPage from '../pages/Brands/GenericBrandLandingPage';
import ExploreHome from '../pages/ExploreHome/ExploreHome';
import MarketAboutUs from '../pages/Market/Public/pages/AboutUs/AboutUs';
import Memberships from '../pages/Memberships/Memberships';
import MembershipsSKU  from '../pages/Memberships/SkuVersion/Memberships';

import MembershipMonthlyPlan from '../pages/Memberships/MembershipMonthlyPlan/MembershipMonthlyPlan';
import CheckoutLegacy from '../pages/Market/Checkout/CheckoutNew';
import CheckoutSKU from '../pages/Checkout/Checkout';
import PaymentConfirmationSKU from '../pages/Checkout/PaymentConfirmation/PaymentConfirmation';
import PaymentLinks from '../pages/Market/Checkout/PaymentLinks/PaymentLinks';
import TalisisLearning from '../pages/Courses/TalisisLearning/TalisisLearning';
import Cart from '../pages/Cart/Cart';

// general
import TerminosCondiciones from '../pages/legal/TerminosCondiciones/TerminosCondiciones';
import AvisoPrivacidad from '../pages/legal/AvisoPrivacidad/AvisoPrivacidad';

// Session
import Login from '../layout/components/Login/Login';
import Signup from '../layout/components/Signup/Signup';
import Password from '../pages/Market/Public/pages/Password/Password';
import PasswordSSO from '../pages/Market/Public/pages/Password/PasswordSSO';
import Auth from '../layout/components/Login/Auth/Auth';
import ResetPassword from '../pages/Access/ResetPassword/ResetPassword';

//
import ContactProspectUnid from '../pages/ContactProspectOu/ContactProspectUnid';
import ContactProspectUerre from '../pages/ContactProspectOu/ContactProspectUerre';
import Events from '../pages/Events/Events';
import ShowEvent from '../pages/Events/ShowEvent/ShowEvent';
import Referrals from '../pages/Referrals/Referrals';

import * as paths from './paths'
import Simplicity from '../pages/Simplicity/Simplicity';

import { NotFound } from '../pages/HttpErrors/404';
import DetailStudyPlanProgram from '../pages/StudyPlan/DetailStudyPlanProgram/DetailStudyPlanProgram';
import DetailStudyPlanCourse from '../pages/StudyPlan/DetailStudyPlanCourse/DetailStudyPlanCourse';
import HelpGuide from '../pages/Platform/helpGuide/helpGuide';

const market = [
    {
        path: paths.CONFIRMACION_PAGO,
        layout: MarketHomeLayout,
        component: MarketPaymentConfirmation,
        isCheckout: true,
        hideFooter: true,
    },
    {
        path: paths.CONFIRMACION_INSCRIPCION,
        layout: MarketHomeLayout,
        component: MarketEnrollmentConfirmation,
        isCheckout: true,
        hideFooter: true
    },
    {
        path: paths.CURSO_DETALLE,
        layout: MarketHomeLayout,
        component: DetailStudyPlanCourse,
        layoutProps:{
            maxWidthContainer: true,
            disableGutters: true
        }
    },
    {
        path: paths.ESCUELAS_OUS,
        layout: MarketHomeLayout,
        component: GenericBrandLandingPage,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        }
    },
    {
        path: paths.BUSQUEDA,
        layout: MarketHomeLayout,
        component: MarketSearch,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        }
    },
    {
        path: paths.PROGRAMA,
        layout: MarketHomeLayout,
        component: DetailStudyPlanProgram,
        layoutProps:{
            maxWidthContainer: true,
            disableGutters: true
        }
    },
    {
        path: paths.ALIADOS,
        layout: MarketHomeLayout,
        component: AlliesLandingPage,
    },
    { 
        path: paths.MEMBRESIAS_OLD,
        layout: MarketHomeLayout,
        component: Memberships,
    },
    { 
        path: paths.MEMBRESIAS,
        layout: MarketHomeLayout,
        component: MembershipsSKU,
    },
    { 
        path: paths.MEMBRESIAS_PLAN_MENSUAL,
        layout: MarketHomeLayout,
        component: MembershipMonthlyPlan,
        layoutProps: {
            maxWidthContainer: false,
            disableGutters: true
        },
        isReferrals: true
    },
    {
        path: paths.CHECKOUT_LEGACY,
        layout: MarketHomeLayout,
        component: CheckoutLegacy,
        isCheckout: true,
        hideFooter: true
    },
    {
        path: paths.CHECKOUT_PAYMENT_LINK,
        layout: MarketHomeLayout,
        component: PaymentLinks,
        isCheckout: true,
        hideFooter: true
    },
    {
        path: paths.CART,
        layout: MarketHomeLayout,
        component: Cart,
        isCheckout: true,
        hideFooter: true
    },
    {
        path: paths.CHECKOUT,
        layout: MarketHomeLayout,
        component: CheckoutSKU,
        isCheckout: true,
        hideFooter: true
    },
    {
        path: paths.PAYMENT_CONFIRMATION,
        layout: MarketHomeLayout,
        component: PaymentConfirmationSKU,
        isCheckout: true,
        hideFooter: true,
    },
    {
        path: paths.CURSOS_TALISIS,
        layout: MarketHomeLayout,
        component: TalisisLearning,
    },
]

const general = [
    {
        path: '/',
        layout: MarketHomeLayout,
        component: ExploreHome,        
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true,
            showProductAssignmentModal: true
        }
    },
    {
        path: paths.SOBRE_NOSOTROS,
        layout: MarketHomeLayout,
        component: MarketAboutUs
    },
    {
        path: paths.TERMINOS_CONDICIONES,
        layout: MarketHomeLayout,
        component: TerminosCondiciones
    },
    {
        path: paths.AVISO_PRIVACIDAD,
        layout: MarketHomeLayout,
        component: AvisoPrivacidad
    },
    {
        path: paths.CONTACTANOS_UNID,
        layout: UnidLayout,
        component: ContactProspectUnid,
        ou: 'unid',
        title: 'hola'
    },
    {
        path: paths.CONTACTANOS_UERRE,
        layout: UerreLayout,
        component: ContactProspectUerre,
        ou: 'unid',
    },
    // {
    //     path: paths.SIMPLICITY,
    //     layout: MarketHomeLayout,
    //     component: Simplicity,
    //     layoutProps:{
    //         maxWidthContainer: false,
    //         disableGutters: true
    //     }
    // },
    {
        path: paths.HTTP_ERROR_404,
        layout: MarketHomeLayout,
        component: NotFound,
    },
    {
        path: paths.REFIERE_UN_AMIGO_PATH,
        layout: MarketHomeLayout,
        component: Referrals,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        isReferrals: true,
    },
    {
        path: paths.HELPGUIDE,
        layout: MarketHomeLayout,
        component: HelpGuide,
    },
]

const session = [
    {
        path: paths.LOGIN,
        layout: MarketHomeLayout,
        component: Login,
        hideNavLinks: true,
        hideNavBar: true,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideFooter: true
    },
    {
        path: paths.SIGNUP,
        layout: MarketHomeLayout,
        component: Signup,
        hideNavLinks: true,
        hideNavBar: true,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideFooter: true
    },
    {
        path: paths.SIGNUP_REFERER_ID,
        layout: MarketHomeLayout,
        component: Signup,
        hideNavLinks: true,
        hideNavBar: true,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideFooter: true
    },
    {
        path: paths.PASSWORD_TOKEN,
        layout: MarketHomeLayout,
        component: Password,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideNavLinks: true,
        hideNavBar: true,
        hideFooter: true
    },
    {
        path: paths.PASSWORD_SSO_TOKEN,
        layout: MarketHomeLayout,
        component: PasswordSSO,
        hideNavLinks: true
    },
    {
        path: paths.AUTH,
        layout: MarketHomeLayout,
        component: Auth,
        hideNavLinks: true,
        hideNavBar: false,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideFooter: true,
        showLoader: true
    },
    {
        path: paths.RECOVER_PASSWORD,
        layout: MarketHomeLayout,
        component: ResetPassword,
        hideNavLinks: true,
        hideNavBar: true,
        layoutProps:{
            maxWidthContainer: false,
            disableGutters: true
        },
        hideFooter: true,
    }
]

const events = [
    {
        path: paths.EVENTOS_DETALLE,
        layout: MarketHomeLayout,
        component: ShowEvent
    },
    {
        path: paths.EVENTOS,
        layout: MarketHomeLayout,
        component: Events
    }
]

const vocational_test = [
    {
        path: paths.TEST_VOC_ONLINE_UNID,
        layout: OnlineVocationalTestLayout,
        ou: 'unid'
    },
    {
        path: paths.TEST_VOC_ONLINE_UERRE,
        layout: OnlineVocationalTestLayout,
        ou: 'uerre'
    },
    {
        path: paths.TEST_VOC_CAMPUS_UNID,
        layout: OnlineVocationalTestLayout,
        ou: 'unid'
    },
    {
        path: paths.TEST_VOC_CAMPUS_UERRE,
        layout: OnlineVocationalTestLayout,
        ou: 'uerre'
    }

]

export default[
    ...market,
    ...session,
    ...general,
    ...events,
    ...vocational_test,
    {
        path: '/redireccionando/:company_id',
        layout: RedirectPage
    },
]
