import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from "react-router-dom";

//material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Card, Divider, Icon, Dialog, Hidden } from '@material-ui/core';

// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/MyConsultanciesStyle';
import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import CalendarConsultancies from './CalendarConsultancies'
import DescriptionConsultancy from './DescriptionConsultancy';
import ModifyConsultancies from './ModifyConsultancies';
import {getLogoCard} from '../../../constants/logosImages';

import RatingConsultancy from './RatingConsultancy';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import * as paths from '../../../routes/paths'
import clsx from 'clsx';
import {APROBADA,PENDIENTE} from '../../../shared/constants/consultancies'

import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';

const mesesx = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const dias_semanax = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const asesoria_entrar = false;

const MyConsultancies = (props) => {
    
    let history = useHistory();
    const classes = useStyles();

    const [DateSelected, setDateSelected] = useState('');
    const [ScheduleSelected, setScheduleSelected] = useState('');
    const [emailAsesor, setEmailAsesor] = useState('');
    const [nombreAsesor, setNombreAsesor] = useState('');
    const [personIdAsesor, setPersonIdAsesor] = useState('');
    const [modalidad, setModalidad] = useState('');
    const [area_conocimiento, setArea_conocimiento] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [ubicacion, setUbicacion] = useState('');
    const [dateConvert, setDateConvert] = useState('');
    const [materiasAsesor, setMateriasAsesor] = useState([]);    
    const [ModifyConsultancy, setModifyConsultancy] = useState(false);
    const [ModifyingOn, setModifyingOn] = useState(false);
    const [ModifyingOnHorarios, setModifyingOnHorarios] = useState(false);
    const [idConsultancy, setIdConsultancy] = useState(0);    
    const [ouConsultancy, setOuConsultancy] = useState('');
    const [CurrentMonth, setCurrentMonth] = useState('');
    const [idTeamsAsesoria, setIdTeamsAsesoria] = useState('');
    const [urlTeams, setUrlTeams] = useState('');
    const [califications, setCalifications] = useState(false);
    const [califico, setCalifico] = useState(-1);
    const [Materia, setMateria] = useState('');
    const [Description, setDescription] = useState('');
    
    useEffect(() => {
        
        props.findConsultancies()
    }, []);

    const convertTime12to24 = time12h => {
        const [time, modifier] = time12h.split(" ");
       
        let [hours, minutes] = time.split(":");
        
        if (modifier == "pm" && parseInt(hours) != 12) {
          hours = parseInt(hours, 10) + 12;
        }
       
        return `${hours}:${minutes}`;
    };

    const handleConvertToTextDate = (data) =>{
        setDateConvert(handleSelectedDay(data))
    }

    const handleSelectedDay = (date) =>{
        const dateFormatted = formatDate(date)
        const fecha = new Date(dateFormatted + "T07:00:00");
        const diaSemana = dias_semanax[fecha.getDay()];
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        //const anio = fecha.getFullYear();
        const cadenaFecha = `${diaSemana} ${dia} de ${mes}`;
        return cadenaFecha
    }

    const formatDate = (date) => {
        let dateFormated = date.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, (_, anio, mes, dia) => {
                return `${anio}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`;
            });
        return dateFormated
    }

    const handleClickEntrar = (email, nombre, person_id, id, url, calificado, botonCalificar=false) =>{
        if(calificado === 0){
            setEmailAsesor(email)
            setNombreAsesor(nombre)
            setIdConsultancy(id)
            setPersonIdAsesor(person_id)
            if(!botonCalificar){
                setTimeout(() => {
                    setCalifications(true);
                }, 10000);
                window.open(url)
            }else{
                setCalifications(true); 
            }  
        }else if (!botonCalificar && calificado !== 0){
            window.open(url)
        }
    }

    const handleSetConsultancies = (consultancies) =>{ 
        setEmailAsesor(consultancies.email_asesor);
        setNombreAsesor(consultancies.nombre_asesor);
        setPersonIdAsesor(consultancies.person_id_asesor);
        setDateSelected(consultancies.fechaLocal.length > 9 ? (consultancies.fechaLocal).slice(0,10) : consultancies.fechaLocal);
        setScheduleSelected(consultancies.horaLocal);
        setModalidad(consultancies.modalidad);
        setArea_conocimiento(consultancies.area_conocimiento);
        setDescripcion(consultancies.descripcion);
        setUbicacion(consultancies.ubicacion);
        setMateriasAsesor(consultancies.materias);
        setDateConvert(dias_semana[new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getDay()] + ' ' + new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getDate() + ' de ' + mesesx[new Date(((consultancies.fechaLocal).slice(0,10))+ ' 00:00:00').getMonth()]);
        setIdConsultancy(consultancies.id);
        setOuConsultancy(consultancies.ou);
        setIdTeamsAsesoria(consultancies.id_teams_meet);
        setUrlTeams(consultancies.url_teams_meet);
        setCalifico(consultancies.calificado);
        setModifyConsultancy(true);
        eventTracker('s_detalles_asesoria', eventData, ['mixpanel']);
    }

    const handleButtonConsultancies = (consultancies, index) => {
        let dateNow = new Date();
        let dateLocal = convertTime12to24(consultancies.horaLocal)
        let dateConsultancy = new Date(((consultancies.fechaLocal).slice(0,10)) + ' ' + convertTime12to24(dateLocal.slice(0,8)) + ':00');
        let tiempoStart = dateConsultancy.getTime() 
        const tiempoMenosMinutos = tiempoStart - 10 * 60 * 1000;
        const tiempoMasMinutos = tiempoStart + 45 * 60 * 1000;
        if(dateNow.getTime() >= tiempoMenosMinutos && dateNow.getTime() <= tiempoMasMinutos && consultancies.status_asesoria === 'APROBADA'){
            asesoria_entrar = true;
            return <Button 
                    key={consultancies.id}
                    variant='contained'
                    color="primary"
                    label="Entrar"
                    className={classes.buttonConsultancies}
                    onClick={() => {handleClickEntrar(consultancies.email_asesor, consultancies.nombre_asesor, consultancies.person_id_asesor, consultancies.id, consultancies.url_teams_meet, consultancies.calificado)}}
                    >   
                        <Typography>Entrar</Typography>
                    </Button>
        }else if(dateNow.getTime() > (tiempoMasMinutos) && consultancies.status_asesoria === 'APROBADA'){ 
            if(consultancies.calificado !== 0){
                return <Button 
                key={consultancies.id}
                variant='outlined'
                color="primary"
                disabled={true}
                label="Terminó"
                className={classes.buttonConsultancies}
                >   
                    <Typography>Terminó</Typography>
                </Button>
            }else{
                return <Button 
                key={consultancies.id}
                variant='outlined'
                color="primary"
                label="Calificar"
                className={classes.buttonConsultancies}
                onClick={() => {handleClickEntrar(consultancies.email_asesor, consultancies.nombre_asesor, consultancies.person_id_asesor, consultancies.id, consultancies.url_teams_meet, consultancies.calificado, true)}}
                >   
                    <Typography>Calificar</Typography>
                </Button>
            }
        }else{            
            return <Button 
            key={consultancies.id}
            variant='outlined'
            color="primary"
            label="Ver detalle"
            className={classes.buttonConsultancies}
            onClick={() => {handleSetConsultancies(consultancies)}}
            >   
                <Typography>Ver detalle</Typography>
            </Button>
        }
    }

    const handleClickRegresar = () => {
        setModifyConsultancy(false)
        setModifyingOnHorarios(false)
        setModifyingOn(false);
        handleResetData()
    }

    const handleDescriptionOn = () => {
        props.findConsultancies();
        handleResetData()
        setModifyingOn(false);
    }

    const handleResetData = () =>{
        setMateria('')
        setDescription('')
    }

    // const handleRedirectPerfil = (id) => {
    //     history.push(generatePath(paths.PERFIL_ASESORIAS, {id}));
    // }

    const handleRegresarModify = () => {
        setModifyConsultancy(false)
    };

    const handleRegresarModifyHorarios = () => {
        setModifyingOnHorarios(false)
    };

    const handleCalifications = () => {
        setCalifications(false)
    };

    return (
        <Box mt="24px">
            <Hidden smDown>
                <Box style={{marginBottom:'20px'}}>
                    <Typography variant='h2'>Mis asesorías</Typography>
                </Box>
            </Hidden>
            {
                props.DataConsultancies.length > 0 ?
                <Box>
                    <Box className={classes.boxRoot}>
                    { 
                        props.DataConsultancies.map((consultancies, index) => (
                            <Card key={consultancies.id} variant='outlined' className={classes.card}>
                                <Box className={classes.boxDataTimes}>
                                    <Box className={classes.boxDataTimesSecond}>
                                        <Icon className={"ri-calendar-line font-size-16 text-electric-blue-200"} />
                                        <Typography variant='body1' className='semi-bold'>{(dias_semana[new Date(((consultancies.fechaLocal).slice(0,10))+' 00:00:00').getDay()] + ' ' + 
                                        new Date(((consultancies.fechaLocal).slice(0,10))+' 00:00:00').getDate() + ' de ' + meses[new Date(((consultancies.fechaLocal).slice(0,10))+' 00:00:00').getMonth()])}</Typography>
                                    </Box>
                                    <Box className={classes.boxDataTimesSecond}>
                                        <Icon className={"ri-time-line font-size-16 text-electric-blue-200"}/>
                                        <Typography variant='body1' className='semi-bold'>{(consultancies.horaLocal)}</Typography>
                                        {
                                            asesoria_entrar &&
                                            <Icon className='ri-checkbox-blank-circle-fill text-error-300 font-size-10' />
                                        }
                                    </Box>
                                    
                                    <Box className={classes.boxStatus}>
                                        <Icon className={clsx("ri-checkbox-blank-circle-fill font-size-10", consultancies.status_asesoria === APROBADA ? "text-success-300" : consultancies.status_asesoria === PENDIENTE && "text-warning-300")}/>
                                        <Typography className="variant-subheading-3" >{consultancies.status_asesoria === APROBADA ? "Solicitud aprobada" : consultancies.status_asesoria === PENDIENTE && "Solicitud en espera"}</Typography>
                                    </Box>
                                </Box>
                                    <Divider className={classes.dividerStyle}/>
                                <Box className={classes.boxAsesorData}>
                                {/* <ProfilePicUsers
                                    key={consultancies.id}
                                    email={consultancies.email_asesor}
                                    onClick={() => {handleRedirectPerfil(consultancies.person_id_asesor)}}
                                    className={classes.profilePic}
                                />       */}
                                <Box className={classes.nameStyle}>
                                    <Typography variant='body1' className='semi-bold'>{consultancies.nombre_asesor}</Typography>
                                    <Typography variant='caption' className='semi-bold text-blue-300'>Asesor</Typography>
                                </Box>
                                </Box>
                                <Box className={classes.boxLogoOu}>
                                    <img alt="logo" className={classes.logoStyle} src={ getLogoCard(consultancies.ou) }/>
                                </Box>
                                <Box className={classes.boxDataSession}>
                                    <Box className={classes.boxSessionSpecific}>
                                        <Icon className={"ri-map-pin-2-line font-size-16 text-fill-stroke-100"} />
                                        <Typography variant='caption'>{consultancies.modalidad}</Typography>
                                    </Box>
                                    <Box className={classes.boxSessionSpecific}>
                                        <Icon className={"ri-book-2-line font-size-16 text-fill-stroke-100"} />
                                        <Typography variant='caption'>{consultancies.area_conocimiento}</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.boxButtonStyle}>
                                    {handleButtonConsultancies(consultancies, index)}
                                </Box>
                            </Card> 
                        ))
                    }
                    </Box>
                        { ModifyConsultancy ?               
                            <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={ModifyConsultancy} onClose={handleRegresarModify}>
                                <ModifyConsultancies
                                    DateSelected={DateSelected} 
                                    ScheduleSelected={ScheduleSelected}
                                    emailAsesor={emailAsesor}
                                    nombreAsesor={nombreAsesor}
                                    personIdAsesor={personIdAsesor}
                                    dateConvert={dateConvert}
                                    modalidad={modalidad}
                                    area_conocimiento={area_conocimiento}
                                    descripcion={descripcion}
                                    ubicacion={ubicacion}
                                    ouConsultancy={ouConsultancy}
                                    idConsultancy={idConsultancy}
                                    setModifyingOn={setModifyingOn}
                                    setModifyConsultancy={setModifyConsultancy}
                                    findConsultancies={props.findConsultancies}
                                    idTeamsAsesoria={idTeamsAsesoria}
                                    creditosAsesorias={props.creditosAsesorias}
                                    handleClickRegresar={handleClickRegresar}
                                />
                            </Dialog>
                        : ModifyingOn ?
                        <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={ModifyingOn} onClose={handleDescriptionOn}>
                                <DescriptionConsultancy 
                                    DateSelected={DateSelected}
                                    setDateSelected={setDateSelected}
                                    ScheduleSelected={ScheduleSelected}
                                    emailAsesor={emailAsesor}
                                    nombreAsesor={nombreAsesor}
                                    personIdAsesor={personIdAsesor}
                                    dateConvert={dateConvert}
                                    modalidad={modalidad}
                                    area_conocimiento={area_conocimiento}
                                    descripcion={descripcion}
                                    ubicacion={ubicacion}
                                    ModifyingOn={ModifyingOn}
                                    idConsultancy={idConsultancy}
                                    materiasAsesor={materiasAsesor}
                                    ouAsesor={ouConsultancy}
                                    setModifyingOn={setModifyingOn}
                                    setModifyingOnHorarios= {setModifyingOnHorarios}
                                    setModifyConsultancy={setModifyConsultancy}
                                    findConsultancies={props.findConsultancies}
                                    setMateria={setMateria}
                                    Materia={Materia}
                                    Description={Description}
                                    setDescription={setDescription}
                                    handleClickRegresar={handleClickRegresar}
                                    idTeamsAsesoria={idTeamsAsesoria}
                                />
                        </Dialog>
                        : ModifyingOnHorarios ?
                        <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={ModifyingOnHorarios} onClose={handleRegresarModifyHorarios}>
                                <CalendarConsultancies 
                                    DateSelected={DateSelected} 
                                    setDateSelected={setDateSelected}
                                    ScheduleSelected={ScheduleSelected}
                                    setScheduleSelected={setScheduleSelected}
                                    emailAsesor={emailAsesor}
                                    nombreAsesor={nombreAsesor}
                                    personIdAsesor={personIdAsesor}
                                    dateConvert={dateConvert}
                                    modalidad={modalidad}
                                    area_conocimiento={area_conocimiento}
                                    descripcion={descripcion}
                                    ubicacion={ubicacion}
                                    ModifyingOn={ModifyingOn}
                                    setModifyingOn={setModifyingOn}
                                    setModifyingOnHorarios= {setModifyingOnHorarios}
                                    setModifyConsultancy={setModifyConsultancy}
                                    handleConvertToTextDate={handleConvertToTextDate}
                                    meses={mesesx}
                                    dias_semana={dias_semanax}
                                    CurrentMonth={CurrentMonth}
                                    setCurrentMonth={setCurrentMonth}
                                    DataConsultancies = {props.DataConsultancies}
                                    handleClickRegresar = {handleClickRegresar}
                                />
                            </Dialog>
                        : califications &&
                        <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={califications} onClose={handleCalifications}>
                            <RatingConsultancy
                            key={idConsultancy}
                            preguntasCalificar={props.preguntasCalificar} 
                            setCalifications={setCalifications} 
                            nombreAsesor={nombreAsesor} 
                            emailAsesor={emailAsesor}
                            idConsultancy={idConsultancy}
                            personIdAsesor={personIdAsesor}
                            findConsultancies={props.findConsultancies}
                            />
                        </Dialog>
                        }
                </Box>
                :
                <Box className={classes.nothingFound}>
                    <NothingFound caption="No tienes asesorías agendadas" subcaption=" " type="misasesorias" />
                </Box>
                
            }
</Box>
    );
};

export default MyConsultancies;