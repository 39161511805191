import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

// material components
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';

import clsx from 'clsx';

import { v4 as uuid } from 'uuid';
import * as paths from '../../../../../../routes/paths';
import DsIcon from '../../../../../../design-system/components/DsIcon/DsIcon';

import { PERMISSION_MY_TALISIS } from '../../../../../../shared/constants/roles';
import { hasPermission } from '../../../../../../services/RoleService';

const useStyles = makeStyles((theme) => ({
    listItem:{
        padding: '4px 8px',
        marginBottom: 6,
        borderRadius: 8,
    },
    listItemAction:{
        '& .MuiListItemIcon-root':{
            border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
            borderRadius: '6.4px',
            minWidth: 32,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .MuiListItemText-root':{
            margin: '4px 13px 4px 10px'
        },
        '& .end-icon':{
            visibility: 'hidden',
            marginBottom: "0px",
            transform: "translate3d(-2px, 0px, 0px)",
            transition: '0.1s ease-in-out ',
        },
        '&:hover':{
            backgroundColor: 'transparent !important',
            '& .MuiListItemIcon-root':{
                border: `1px solid ${theme.palette.colors.fillStroke[100]}`
            },

            '& .MuiListItemText-root':{
                color: theme.palette.colors.gray[500],
                textDecoration: 'underline',
                width: 'fit-content'
            },

            '& .end-icon':{
                visibility: 'visible',
                transform: "translate3d(0px, 0px, 0px) !important",
                transition: '0.1s ease-in-out opacity 0.2s linear !important',
            },
        }
    }
}));

const sectionsList = [
    // {label: 'Asesorías', path: paths.ASESORIAS, isLogged: true, icon: 'ri-slideshow-line'},
    {label: 'Eventos', path: paths.EVENTOS, icon:'ri-calendar-2-line'},
    {label: 'Blog', href: paths.BLOG, icon:'ri-chat-quote-line'},
];

const roleMenuItems = [
    {permissions: [PERMISSION_MY_TALISIS], label: 'My Talisis', href: paths.MY_TALISIS, icon: <DsIcon icon='t-app' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{width:16, height: 16}}/>},
]

export default function ShortcutList(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const reloadRedirect = (event, item) => {
        //has external href
        if(item.hasOwnProperty('href')){    
            const target = item.target || '_blank';
            window.open(item.href, target);
            return;
        }

        //has path inside app
        event.preventDefault();
        const path = item.path || '';
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;

        //navigate to path (with history)
        if(currentPath === gotToPathname){
            history.push(path);
            window.location.reload();
        }else{
            history.push(path)
        }
    }

    const ButtonLink = ({item}) => (
        <Button className={clsx(classes.listItem, classes.listItemAction)} onClick={(event) => reloadRedirect(event, item)} key={uuid()} component={ListItem} disableRipple disableFocusRipple>
            <ListItemIcon>
                {
                    typeof item.icon === "string"
                    ? <Icon className={`${'font-size-14 text-gray-100'} ${item.icon}`}/>
                    : <Icon className='font-size-14 text-gray-100'>

                        {item.icon}
                    </Icon>
                }
            </ListItemIcon>
            <ListItemText primary={
                <Typography variant="subtitle2" className="text-gray-500">
                    {item.label}
                </Typography>
            }/>
            <Icon className={clsx('font-size-14', 'text-orange-300','end-icon',{ 'ri-arrow-right-line':item.path, 'ri-external-link-line':item.href})}/>
        </Button>
    )

    return (
        <List component="nav" aria-label="main mailbox folders">
            {
                sectionsList.filter(f => props.isLogged ? f : !f.isLogged).map((item) =>
                    <ButtonLink item={item}/>
                )
            }
            {
                roleMenuItems.filter(roleObj => roleObj.permissions.some(permission => hasPermission(permission))).map(item =>   
                    <ButtonLink item={item}/>
                )
            }
        </List>
    );
}