import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

//material
import Typography from '@material-ui/core/Typography';
import { withStyles,Card, Divider, Icon, IconButton, Button, Box, Select, FormControl, Tooltip, MenuItem, List, ListItem, Dialog, InputAdornment} from '@material-ui/core';

// style
import useStyles from '../../../assets/styles/jss/pages/consultancies/applicant/CardAdviserStyle';
import ProfilePicUsers from '../../../shared/components/ProfilePicUsers/ProfilePicUsers';
import Toast from '../../../shared/components/Toast/Toast';
import CalendarConsultancies from './CalendarConsultancies'
import DescriptionConsultancy from './DescriptionConsultancy';
import {getLogoCard} from '../../../constants/logosImages';
//SDK
import { getSessionCookie } from '../../../shared/utils/Sessions';
import capitalize from '../../../design-system/utils/capitalize';
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';
import { generatePath } from "react-router";
import * as paths from '../../../routes/paths'
import { ConsultanciesService } from '@sdk-point/talisis'; 
import {MEMBERSHIPS} from '../../../shared/constants/memberships'
import { Pagination, Rating } from '@material-ui/lab';
import StarBorder from '@material-ui/icons/StarBorder';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';


let newDate = '';
let dateMax = '';
let dateToday = '';
const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const menuVista = [{value: 12, label: "12 / Vista"}, {value: 24, label: "24 / Vista"},{value: 48, label: "48 / Vista"}]

const ConsultanciesServiceSDK = new ConsultanciesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const CardAdviser = (props) => {
    
    const classes = useStyles();
    let history = useHistory();
    const MAX_MATERIAS = 2;

    const [CalendarWeek, setCalendarWeek] = useState(false);
    const [DateSelected, setDateSelected] = useState('');
    const [ScheduleSelected, setScheduleSelected] = useState('');
    const [emailAsesor, setEmailAsesor] = useState('');
    const [nombreAsesor, setNombreAsesor] = useState('');
    const [ouAsesor, setOuAsesor] = useState('');
    const [materias, setMaterias] = useState([]);
    const [personIdAsesor, setPersonIdAsesor] = useState('');
    const [dateConvert, setDateConvert] = useState('');
    const [CurrentMonth, setCurrentMonth] = useState('');
    const [asesores, setAsesores] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCredits, setOpenCredits] = useState(false);
    const [descriptionOn, setDescriptionOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numFoundData, setNumFoundData] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [skillsFilter, setSkillsFilter] = useState([]);
    const [textButton, setTextButton] = useState('Área de conocimiento');
    const [Materia, setMateria] = useState('');
    const [Description, setDescription] = useState('');
    const [numAsesoresVista, setNumAsesoresVista] = useState(12);
    
    const { user } = useSelector((state) => state.userReducer);

    useEffect(() => {
        findAdvisers();
        newDate = new Date()
        dateToday = new Date()
        setCurrentMonth(dateToday.getMonth());
        dateMax = new Date(newDate.setMonth(newDate.getMonth()+4));
    }, []);

    const findAdvisers = async (page=1,size=12,filter) => {
        let response = await ConsultanciesServiceSDK.getAdvisersLimits(page,size,filter)
        const updatedData = response.advisers.map(item => ({
            ...item,
            fullname:
              item.fullname.length > 18
                ? `${item.fullname.split(' ')[0]} ${item.fullname.split(' ')[1].charAt(0)}.`
                : item.fullname.split(' ').map(capitalize).join(' ')
          }));
        setAsesores(updatedData);
        setNumFoundData(response.countTotal)
        let resultArr = arrayToDropdown(response.areasConocimiento)
        setSkillsFilter(resultArr)
        setLoading(false)
    }

    const handleChangeDate = (data) => {
        setCalendarWeek(false)
        setScheduleSelected(data.replace(/['"]+/g, ''))
    }

    const handleConvertToTextDate = (data) =>{ 
        setDateConvert(handleSelectedDay(data))
    }

    const handleSelectedDay = (date) =>{
        const dateFormatted = formatDate(date)
        const fecha = new Date(dateFormatted + "T07:00:00");
        const diaSemana = dias_semana[fecha.getDay()];
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        //const anio = fecha.getFullYear();
        const cadenaFecha = `${diaSemana} ${dia} de ${mes}`;
        return cadenaFecha
    }


    const formatDate = (date) => {
        let dateFormated = date.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, (_, anio, mes, dia) => {
                return `${anio}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`;
            });
        return dateFormated
    }

    const arrayToDropdown = (result) => {
        const sortedArray = result.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }));
        const categorizedArray = [];
        
        let lastCategory = '';
        
        sortedArray.forEach((item) => {
            const category = item.label.charAt(0).toUpperCase();
        
            if (category !== lastCategory) {
            categorizedArray.push({ category });
            lastCategory = category;
            }
        
            categorizedArray.push({ id: item.id, label: item.label });
        });
        
        return categorizedArray;
    };

    const handleClickAgendar = (emailAsesor,nombreAsesor,personId,ou,materias) => {
        eventTracker('agendar_asesoria', eventData, ['mixpanel']);
        let session = getSessionCookie();
        if(personId !== session.person_id){
            if(props.creditosAsesorias > 0 || user.membership_id === MEMBERSHIPS.PREMIUM){
                setEmailAsesor(emailAsesor);
                setNombreAsesor(nombreAsesor);
                setPersonIdAsesor(personId);
                setMaterias(materias);
                setOuAsesor(ou);
                window.scrollTo(0, 0);
                setCurrentMonth(dateToday.getMonth());
                setCalendarWeek(true);
                eventTracker('s_calendario_asesoria', eventData, ['mixpanel']);
            }else{
                setOpenCredits(true);
            }
        }else{
            setOpen(true);
        }
    }

    const handleClickRegresar = () => {
        setCalendarWeek(false);
        setDateSelected('');
        setDescriptionOn(false);
        setScheduleSelected('');
    }

    const handleRedirectPerfil = (id) => {
        eventData.IdMaestro = id
        eventTracker('ver_perfil_maestro', eventData, ['mixpanel'])
        history.push(generatePath('', {id}));
    }
    
    const handlePageChange = (event,page) => {
        setLoading(false)
        window.scrollTo(0, 0)
        setCurrentPage(page);
        findAdvisers(page, numAsesoresVista,textButton === 'Área de conocimiento' ? '' : textButton);
        setLoading(true)
    };
    
    const handleClickFilter = (item) => {
        setLoading(false)
        window.scrollTo(0, 0)
        setTextButton(item.label)
        setCurrentPage(1)
        findAdvisers(1, numAsesoresVista,item.label);
        setLoading(true)
    }; 
    
    const handleClickResetFilter = () => {
        setLoading(false)
        window.scrollTo(0, 0)
        setTextButton('Área de conocimiento')
        handlePageChange('', 1)
        findAdvisers();
        setLoading(true)
    };
    
    const handleAsesoresVista = (event) => {
        setCurrentPage(1);
        setNumAsesoresVista(event.target.value)
        findAdvisers(1, event.target.value,textButton === 'Área de conocimiento' ? '' : textButton);
    }
    
    const handleDescriptionOff = () => {
        setCalendarWeek(true)
    }
    
    return (
        <Box mt="24px" mb="40px">
            <LoaderContent loading={loading}>
            <Box>
                <Box className={classes.boxFilter}>
                    <Typography variant='body2' className={classes.filterText}>Buscar por:</Typography>
                    <Dropdown
                        text={textButton}
                        options={skillsFilter}
                        classStyleItems={classes.styleDropdown}
                        color="outlined"
                        endIcon={<Icon className="ri-arrow-down-s-line text-white"/>} 
                        className={classes.filterSkill}
                        onClickOption={handleClickFilter}
                    />
                    
                    {
                        textButton !== 'Área de conocimiento' &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={()=>{handleClickResetFilter() }}
                        >
                            <Icon className="ri-close-fill text-fill-stroke-100" />
                        </IconButton>
                    }
                </Box>
                    <Box className={classes.boxRoot}>
                    { 
                        asesores.length > 0 ?
                        asesores.map(asesor => 
                            <Card  key={asesor.id} className={classes.card}>
                                <Box key={asesor.id + 'info-asesor'}  className= {classes.boxInfoAsesor}>
                                    <ProfilePicUsers key={asesor.id + 'profile-pic'} onClick={() => {handleRedirectPerfil(asesor.id)}} email={asesor.email} className={classes.profilePic}/>
                                    <Box className={classes.nameAdviser}>
                                        <Button style={{padding:'0px'}}>
                                            <Typography onClick={() => {handleRedirectPerfil(asesor.id)}} variant='h4'>{asesor.fullname}</Typography>
                                        </Button>
                                        <img alt="logo" className={classes.logoStyle} src={ getLogoCard(asesor.ou) }/>
                                    </Box>
                                    <Box className={classes.boxCalificacionesStyle}>
                                        <Box className={classes.boxCalificacionStyle}>
                                            <Typography align='center' className={clsx(classes.calificacionStyle,"semi-bold")}>{parseFloat(asesor.calificacion) || 0}</Typography>
                                        </Box>
                                        <Rating
                                            readOnly
                                            precision={0.5}
                                            value={parseFloat(asesor.calificacion) || 0}
                                            name="consultancies_rating"
                                            emptyIcon={<StarBorder fontSize="inherit" />}
                                        />
                                        <Typography variant='caption'>({asesor.num_asesorias})</Typography>
                                    </Box>
                                </Box>
                                <Divider className={classes.dividerStyle}></Divider>
                                <Typography variant='overline' className='variant-overline-sm'>ÁREAS DE CONOCIMIENTO</Typography>
                                <Box className={classes.boxMaterias}>
                                    {asesor.materias.length <= MAX_MATERIAS ? 
                                    asesor.materias.map((materia,i) => 
                                        <Box key={i} className={i === 0 ? classes.boxMateriaFirst : classes.boxMateria}>
                                            <Tooltip
                                            typographyprops={{variant: 'caption'}}
                                            classes={{ tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipText }}
                                            title={materia}
                                            placement="bottom-end">
                                                <Typography variant='caption' className={classes.materiaStyle}>{materia}</Typography>
                                            </Tooltip>
                                        </Box>
                                    )
                                        :
                                    asesor.materias.slice(0,2).map((materia,i) => 
                                        <Box key={i} className={classes.boxMateria}>
                                            <Tooltip
                                            typographyprops={{variant: 'caption'}}
                                            classes={{ tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipText }}
                                            title={materia}
                                            placement="bottom-end">
                                                <Typography variant='caption' className={classes.materiaStyle}>{materia}</Typography>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    {asesor.materias.length > MAX_MATERIAS && 
                                        <Tooltip
                                        typographyprops={{variant: 'caption'}}
                                        classes={{ tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipText }}
                                        title={<List dense={true}>{asesor.materias.slice(2).map(materia => <ListItem key={uuid()}>{materia}</ListItem>)}</List>} 
                                        placement="bottom-end">
                                            <Box className={classes.boxMateria}>
                                                <Typography variant='caption' className={classes.materiaStyle}>...</Typography>
                                            </Box>
                                        </Tooltip>
                                    }                                    
                                </Box>
                                <Button 
                                color="primary"
                                label="Button"
                                size="medium"
                                variant="outlined" 
                                className={classes.buttonAgendar}
                                onClick={() => {handleClickAgendar(asesor.email, asesor.fullname, asesor.id, asesor.ou,asesor.materias)}}>
                                    <Typography variant='body2' className="semi-bold">Agendar</Typography>
                                </Button>
                        </Card> 
                    )
                    :
                    <Box className={classes.nothingFound}>
                        <NothingFound caption="No hay asesores disponibles por el momento" subcaption=" " type="asesores" />
                    </Box>
                    }
                    </Box>
                <Box className={classes.paginationContainer}>
                    <Pagination
                        key={numAsesoresVista}
                        current={currentPage}
                        onChange={handlePageChange}
                        count={numFoundData ? Math.ceil(numFoundData / numAsesoresVista) : 1}
                        pageSize={numAsesoresVista}
                        showsizechanger={false}
                        showQuickJumper={false}
                        className={classes.paginationStyle}
                    />
                    <FormControl>
                        <Select
                        value={numAsesoresVista}
                        onChange={handleAsesoresVista}
                        className={classes.selectVista}
                        inputProps={{
                            className: clsx('variant-xsmall semi-bold',classes.inputSelectStyle)
                        }}
                        >
                            {
                                menuVista.map((item) =>
                                    <MenuItem key={uuid()} value={item.value}>{item.label}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
                {
                CalendarWeek ?                    
                    <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={CalendarWeek} onClose={handleClickRegresar}>
                        <CalendarConsultancies setDateSelected = {setDateSelected} 
                        setScheduleSelected = {handleChangeDate}
                        emailAsesor={emailAsesor}
                        nombreAsesor={nombreAsesor}
                        personIdAsesor={personIdAsesor}
                        handleConvertToTextDate={handleConvertToTextDate}
                        meses={meses}
                        dias_semana={dias_semana}
                        CurrentMonth={CurrentMonth}
                        setCurrentMonth = {setCurrentMonth}
                        handleClickRegresar={handleClickRegresar}
                        DataConsultancies = {props.DataConsultancies}
                        setDescriptionOn = {setDescriptionOn}
                        />
                    </Dialog>
                : descriptionOn &&
                    <Dialog classes={{paperWidthSm: classes.paperDialogStyle}} open={descriptionOn} onClose={handleClickRegresar}>
                        <DescriptionConsultancy handleClickRegresar={handleClickRegresar} 
                            DateSelected={DateSelected} 
                            ScheduleSelected={ScheduleSelected}
                            emailAsesor={emailAsesor}
                            nombreAsesor={nombreAsesor}
                            ouAsesor={ouAsesor}
                            materiasAsesor={materias}
                            personIdAsesor={personIdAsesor}
                            dateConvert={dateConvert}
                            findConsultancies={props.findConsultancies}
                            creditosAsesorias={props.creditosAsesorias}
                            setMateria={setMateria}
                            Materia={Materia}
                            Description={Description}
                            setDescription={setDescription}
                            setScheduleSelected={setScheduleSelected}
                            handleDescriptionOff={handleDescriptionOff}
                            setDescriptionOn={setDescriptionOn}
                        />
                    </Dialog>
                }

                
            </Box>
            </LoaderContent>
            <Toast
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
            }}
            message="No puedes agendar una asesoría a ti mismo"
            onFinished={() => {setOpen(false)}}
            open={open}
            severity="error"
            />

            <Toast
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
            }}
            message="Necesitas obtener primero créditos, puedes hacerlo obteniendo una membresía"
            onFinished={() => {setOpenCredits(false)}}
            open={openCredits}
            severity="info"
            />

        </Box>
    );
};

export default CardAdviser;