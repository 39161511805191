import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import BirreteLine from '../../../assets/images/icons/mis-cursos-line.svg'
import BirreteFill from '../../../assets/images/icons/mis-cursos-fill.svg'

// redux
import { connect, useSelector } from 'react-redux';
import { setActiveTab, setActiveSubTab } from '../../../redux/actions/tabsActions';

import * as paths from '../../../routes/paths';
import LogOut from '../../components/Login/LogOut/LogOut'

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Badge, Divider, Hidden, Typography } from '@material-ui/core';
import { INFO_PAGO_TAB_INDEX, PREFERENCIAS_TAB_INDEX } from '../../../shared/constants/tabs';
import ReferralPlanMssg from '../../../pages/ReferralPlan/components/ReferralPlanMssg';
import { getRolesByUser, getPermissionsByUser } from '../../../services/RoleService';
import { ROLE_PROMOTOR, PERMISSION_MY_TALISIS } from '../../../shared/constants/roles';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';

const useStyles = makeStyles((theme) => ({
    root:{
        position: 'fixed',
        bottom:0,
        backgroundColor: theme.palette.colors.darkBlack[500],
        width: '100%',
        zIndex: 1301,
    },
    list:{
        display: 'flex',
        width: '100%',
        padding: 0
    },
    listItem:{
        flexDirection: 'column',
        '& .MuiListItemIcon-root':{
            minWidth: 'auto'
        }
    },
    drawer:{
        '& .MuiDrawer-paper':{
            bottom: 58
        }
    },
    listItemMenu:{
            borderBottom: '0.5px solid #404150',
        '& .MuiListItemIcon-root':{
            minWidth: 40
        },
    },
    gutters:{
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    icon:{
        fontSize: 18,
    },
    header:{
        background: theme.palette.black,
        position: 'fixed',
        padding: '18px 0px',
        textAlign: 'center',
        width: '100%',
        zIndex: 3
    },
    [theme.breakpoints.down('xs')]:{
        drawer:{
            '& .MuiDrawer-paper':{
                bottom: 0,
                height: '100%',
                background: theme.palette.colors.fillStroke[500]
            },
            '& .drawer-presentation':{
                height: '100%',
                paddingTop: 72
            },
        },
        menu:{
            '& ul':{
                padding: '0px 16px 58px',
            }
        }
    },
    notification:{
        '&:hover .MuiTypography-root':{
            fontWeight: 600
        },
        '& .MuiBadge-dot':{
            top: '11px',
            right: '-11px',
            height: 7,
            minWidth: 7,
            [theme.breakpoints.down('xs')]: {
                top: '4px',
                right: '-20px',
            }
        }
    },
}));

const menuList = [
    {icon: 'ri-user-star-line', label: 'Membresía', path: paths.MEMBRESIAS},
    {icon: 'ri-calendar-todo-fill', label: 'Horario de clases', path: paths.PERFIL, tabIndex: 1, subTabIndex: 0},
    // {icon: 'ri-account-pin-box-line', label: 'Asesorías', path: paths.ASESORIAS},
    {icon: 'ri-bar-chart-fill', label: 'Mis calificaciones', path: paths.PERFIL, tabIndex: 1, subTabIndex: 1},
    {icon: 'ri-file-list-3-line', label: 'Historial académico', path: paths.PERFIL, tabIndex: 1, subTabIndex: 2},
    {icon: 'ri-bank-line', label: 'Información de pago', path: paths.PERFIL, tabIndex: INFO_PAGO_TAB_INDEX, subTabIndex: 0},
    // {icon: 'ri-briefcase-2-line', label: 'Conecta', path: paths.SIMPLICITY},
    // {icon: 'ri-briefcase-2-line', label: 'My Talisis', path: paths.MY_TALISIS},
    {icon: 'ri-settings-4-line', label: 'Preferencias', path: paths.PERFIL, tabIndex: PREFERENCIAS_TAB_INDEX, subTabIndex: 0},
    {icon: 'ri-quill-pen-fill', label: 'Términos y Condiciones', path: paths.TERMINOS_CONDICIONES},
    {icon: 'ri-eye-off-line', label: 'Aviso de Privacidad', path: paths.AVISO_PRIVACIDAD}, 
]

const roleMenuItems = [
    { icon: 'ri-qr-code-line', label: 'Refiere y gana', path: '/refiere-y-gana', tabIndex: null, roles: [ROLE_PROMOTOR]},
    { icon: <DsIcon icon='t-app' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{width:16, height: 16}}/>, label: 'My Talisis', href: paths.MY_TALISIS, tabIndex: null, permissions: [PERMISSION_MY_TALISIS]},
]

const BottomMenu = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {restrictions} = useSelector((state) => state.userReducer.user);
    const menu = [
        {icon: 'ri-home-4-line', iconActive: 'ri-home-5-fill', label: 'Inicio', path:  paths.HOME, notification: restrictions && restrictions.length},
        {icon: 'ri-compass-3-line', iconActive: 'ri-compass-3-fill', label: 'Explorar', path: '/'},
        {icon: '', iconActive: '', label: 'Mis clases', path: paths.CURSOS},
        {icon: 'ri-account-circle-line', iconActive: 'ri-account-circle-fill', label: 'Perfil', path: paths.PERFIL},
    ]

    const [open, setOpen] = React.useState(false)
    const [moreOptions, setMoreOptions] = React.useState(false)
    
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMoreOptions(open)
        setOpen(open);
    };

    const redirect = (event, {path = '', href = ''}, tabIndex = null, subTabIndex = null) => {
        event.preventDefault();

        //has external href
        if(href){    
            window.open(href);
            return;
        }
    
        history.push(path);

        if (Number.isInteger(tabIndex)){
            props.setActiveTab(tabIndex);
            props.setActiveSubTab(subTabIndex)
        }
    }

    const ListItemByPermission = (item) => (
        <ListItem button className={classes.listItemMenu} onClick={(event) => redirect(event, item, item.tabIndex, item.subTabIndex)} key={uuid()}>
            <ListItemIcon>
                {
                    typeof item.icon === "string"
                    ? <Icon className={clsx(item.icon, 'text-gray-100')}/>
                    : <Icon className={clsx(item.icon, 'text-gray-100')}>
                        {item.icon}
                    </Icon>
                }
            </ListItemIcon>
            <ListItemText 
                primary={item.label} 
                primaryTypographyProps={{ variant: 'body1', className: 'text-gray-100'}}
            />
        </ListItem> 
    )
    
    return (
        <Hidden mdUp>
            <Box className={classes.root} id="bottom-menu-root">
                <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)} className={classes.drawer}>
                    <Box className={classes.header}>
                        <Typography variant='h4'>Ver más</Typography>
                    </Box>
                    <Box
                        width="auto"
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        className='drawer-presentation'
                    >
                        <ReferralPlanMssg />

                        <Box className={classes.menu}>
                            <List className="padding-3">
                                {menuList.map((item) => (
                                    <ListItem button className={classes.listItemMenu} onClick={(event) => {
                                        redirect(event, item, item.tabIndex, item.subTabIndex);
                                        // if (item.label === 'Asesorías') {
                                        //     eventTracker('ver_asesorias', {}, ['mixpanel']);
                                        // }
                                        if(item.label === 'Mis clases'){
                                            const eventData = {};
                                            eventData['source'] = 'nav_bar';
                                            eventTracker('ver_mis_clases', eventData, ['mixpanel']);
                                        }
                                    }} key={uuid()}>
                                        <ListItemIcon>
                                            <Icon className={clsx(item.icon, 'text-gray-100')}/>
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={item.label} 
                                            primaryTypographyProps={{ variant: 'body1', className: 'text-gray-100'}}
                                        />
                                    </ListItem>
                                ))}

                                {
                                    roleMenuItems.filter(r => r.hasOwnProperty('roles')).filter(item => item.roles.some(role => getRolesByUser().includes(role))).map(item => 
                                        <ListItemByPermission {...item} />
                                    )
                                }
                                {
                                    roleMenuItems.filter(r => r.hasOwnProperty('permissions')).filter(item => item.permissions.some(role => getPermissionsByUser().includes(role))).map(item => 
                                        <ListItemByPermission {...item} />
                                    )
                                }
                                <LogOut component={ListItem} key={uuid()} className="padding-4">
                                    <ListItemText 
                                        primary="Cerrar sesión" 
                                        primaryTypographyProps={{ variant: 'body1', className: 'text-orange-300 semi-bold'}}
                                    />
                                </LogOut>
                            </List>
                        </Box>
                        {/* <Box px={2}>
                            <Divider className="bg-fill-stroke-200"/>
                        </Box> */}
                    </Box>
                </Drawer>
                <Box>
                    <List component="nav" className={classes.list}>
                        {
                            menu.map(item =>                        
                                <ListItem classes={{ gutters: classes.gutters }} button className={classes.listItem} component={RouterLink} to={item.path} key={uuid()} onClick={(event) => redirect(event, item)}>
                                    <ListItemIcon>
                                    {
                                        item.label === 'Mis clases' ? 
                                            <img src={location.pathname === item.path  ? BirreteFill : BirreteLine} className={classes.icon} />
                                        :
                                        <Badge color="error" variant="dot" className={classes.notification} invisible={!item.notification}>
                                            <Icon className={clsx(
                                                location.pathname === item.path ? `text-violet-300 ${item.iconActive}` : `text-dark-black-100 ${item.icon}`,
                                                'font-size-18'
                                            )}/>
                                        </Badge>
                                    }
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={item.label} 
                                        primaryTypographyProps={{className: clsx('variant-subheading-4 text-gray-100',{'font-weight-700': location.pathname === item.path} ),
                                        align: 'center'
                                        }}
                                    />
                                </ListItem>
                            )
                        }
                        <ListItem button className={classes.listItem} classes={{ gutters: classes.gutters }} key={uuid()} onClick={toggleDrawer(!open)}>
                            <ListItemIcon>
                                <Icon className={!moreOptions ? "ri-more-line text-dark-black-100 font-size-18" : "ri-more-fill text-violet-300 font-size-18"}/>
                            </ListItemIcon>
                            <ListItemText 
                                primary="Ver más" 
                                primaryTypographyProps={{className: 'variant-subheading-4 text-gray-100'}}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Hidden>
    );
};

const mapDispatchToProps = { setActiveTab, setActiveSubTab };

export default connect(null, mapDispatchToProps)(BottomMenu);